"use client";

import { useForm } from "react-hook-form";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { Alert, AlertDescription, AlertTitle } from "@/components/ui/alert";
import { Button, buttonVariants } from "@/components/ui/button";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { useRequestTokenMutation } from "@/api/login";
import Loader from "@/components/ui/loader";
import { useRouter } from "next/navigation";
import Link from "next/link";
import { cn } from "@/lib/utils";

interface FormData {
  email: string;
}

const formSchema = z.object({
  email: z.string().email(),
});

export default function Login() {
  const mutation = useRequestTokenMutation();
  const router = useRouter();

  const form = useForm<FormData>({ resolver: zodResolver(formSchema) });
  const onSubmit = form.handleSubmit((data) =>
    mutation.mutateAsync(data).then(() => {
      router.push("/login/code");
    }),
  );

  return (
    <Form {...form}>
      {mutation.isError && (
        <Alert variant="destructive" className="mb-3">
          <AlertTitle>Error</AlertTitle>
          <AlertDescription>An error occurred.</AlertDescription>
        </Alert>
      )}
      <form
        onSubmit={onSubmit}
        className="flex w-full flex-col items-center space-y-6"
      >
        <div className="mx-auto flex w-full flex-col justify-center space-y-6 sm:w-[350px]">
          <div className="flex flex-col space-y-2 text-center">
            <h1 className="text-2xl font-semibold tracking-tight">Log in</h1>
            <p className="text-sm text-muted-foreground">
              Enter your email below to log in if you aleady have an account
            </p>
          </div>
          <FormField
            control={form.control}
            name="email"
            render={({ field }) => (
              <FormItem className="w-full">
                <FormLabel>Email</FormLabel>
                <FormControl>
                  <Input placeholder="wiggo@sky.com" {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <Button
            type="submit"
            className="w-full"
            disabled={mutation.isPending}
          >
            {mutation.isPending ? <Loader /> : "Continue"}
          </Button>
          <div className="relative">
            <div className="absolute inset-0 flex items-center">
              <span className="w-full border-t" />
            </div>
            <div className="relative flex justify-center text-xs uppercase">
              <span className="bg-background px-2 text-muted-foreground">
                Don&apos;t have an account?
              </span>
            </div>
          </div>
          <div>
            <Link
              href="/signup"
              className={cn(buttonVariants({ variant: "secondary" }), "w-full")}
            >
              Sign Up
            </Link>
          </div>
        </div>
      </form>
    </Form>
  );
}
