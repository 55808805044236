import { Loader2 } from "lucide-react";

import { cva, type VariantProps } from "class-variance-authority";
import { cn } from "@/lib/utils";
import React from "react";

const loaderVariants = cva("h-4 w-4 animate-spin ", {
  variants: {
    variant: {
      onLight: "stroke-primary-foreground",
      onDark: "stroke-primary",
    },
  },
  defaultVariants: {
    variant: "onLight",
  },
});

export interface ButtonProps
  extends React.SVGLineElementAttributes<SVGElement>,
    VariantProps<typeof loaderVariants> {}

export const Loader = ({ className, variant }: ButtonProps) => {
  return <Loader2 className={cn(loaderVariants({ variant, className }))} />;
};

Loader.displayName = "Loader";

export default Loader;
